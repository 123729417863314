<template>
    <div class="skelton">
        <div class="container-fluid">
            <div class="row">
                <div class="col pad-0" style="max-height:100vh;border-right:1px solid #ddd;max-width:14%;overflow-y:auto;">
                    <div class="side-header bx-shadow" style="min-height:100vh;">
                        <div class="brand-head pad-20 text-center">
                            <h4>Admin</h4>
                            <!-- <img src="/logo.png" alt="" class="img-responsive img-fluid"> -->
                        </div>
                        <ul class="navbar-nav">
                            <li>
                                <span class="text-warning" style="font-size:1.2em;font-weight:bold;">System</span>
                            </li>
                            <li :class="{'active': $route.name==='Dashboard'}">
                                <router-link to="/">Dashboard</router-link>
                            </li>
                            <!-- <li :class="{'active': $route.name==='Financials'}">
                                <router-link to="/financials">Reports</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Reports'}">
                                <router-link to="/reports/">Reports</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Scheduler'}">
                                <router-link to="/scheduler/">Scheduler</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Tasks'}">
                                <router-link to="/tasks/">Tasks</router-link>
                            </li> -->
                            <li>
                                <span class="text-warning" style="font-size:1.2em;font-weight:bold;">User Master</span>
                            </li>
                            <li :class="{'active': $route.name==='Actions'}">
                                <router-link to="/requests-actions/">Requests</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Communication'}">
                                <router-link to="/communication/">Communication</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Users'}">
                                <router-link to="/users/">Users</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Transactions'}">
                                <router-link to="/transactions/">Transactions</router-link>
                            </li>
                            <!-- <li :class="{'active': $route.name==='Subscriptions'}">
                                <router-link to="/subscriptions/">Subscriptions</router-link>
                            </li>
                             <li :class="{'active': $route.name==='Payments'}">
                                <router-link to="/payments/">Payments</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Profiles'}">
                                <router-link to="/profiles/">Profiles</router-link>
                            </li> -->
                            <li :class="{'active': $route.name==='Products'}">
                                <router-link to="/products/">Products</router-link>
                            </li>
                            <!-- <li :class="{'active': $route.name==='Enrollments'}">
                                <router-link to="/enrollments/">Enrollments</router-link>
                            </li> -->
                            <li>
                                <span class="text-warning" style="font-size:1.2em;font-weight:bold;">Admin Master</span>
                            </li>
                            <!-- <li :class="{'active': $route.name==='Plans'}">
                                <router-link to="/plans/">Membership List</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Courses'}">
                                <router-link to="/courses/">Courses</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Questionaries'}">
                                <router-link to="/questionaries/">Questionaries</router-link>
                            </li> -->
                            <li :class="{'active': $route.name==='Lookups'}">
                                <router-link to="/lookups/">Lookups</router-link>
                            </li>
                            <!-- <li :class="{'active': $route.name==='Settings'}">
                                <router-link to="/Settings/">Settings</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Currencies'}">
                                <router-link to="/currencies/">Currencies</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Discounts'}">
                                <router-link to="/discounts/">Offers</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Coupons'}">
                                <router-link to="/coupons/">Coupons</router-link>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Tax'}">
                                <router-link to="/tax/">Taxes</router-link>
                            </li>
                            <li :class="{'active': $route.name==='Banners'}">
                                <router-link to="/banners/">Banners</router-link>
                            </li> -->
                            <!-- <li>
                                <span class="text-warning">Account &amp; Plans</span>
                            </li> -->
                            <!-- <li :class="{'active': $route.name==='Transactions'}">
                                <router-link to="/transactions/">Transactions</router-link>
                            </li> -->
                            <!-- <li>
                                <span class="text-warning">Courses &amp; Requests</span>
                            </li>
                            <li>
                                <span class="text-warning">GoCoins &amp; Transactions</span>
                            </li>
                            <li>
                                <span class="text-warning">Settings</span>
                            </li> -->
                        </ul>
                        <br>
                        <br>
                    </div>
                </div>
                <div class="col pad-0" style="max-height:100vh;overflow-y:auto;">
                    <div class="pad-15 bg-white bx-shadow text-right" style="border-bottom: 1px solid #ddd;">
                        <span @click="$cs.auth.logout()" class="c-pointer"><span class="fa fa-power-off text-danger"></span> Logout</span>
                    </div>
                    <div class="row margin-0">
                        <div class="col pad-0" style="max-height:calc(100vh - 55px);overflow-y:auto;">
                            <router-view></router-view>
                            <!-- <div class="text-center">
                                <p style="color: rgb(195, 194, 194);font-weight: 700;letter-spacing: 0px;">Powered by: Codejunkers</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            profile: JSON.parse(localStorage.getItem('user_details')) || null,
            isLoading: true
        }
    },
    watch: {
        lookups: function() {
            if(this.lookups) {
                this.isLoading = false;
            }
        }
    },
    created() {
        if(localStorage.getItem('APP_VERSION')===null) {
            localStorage.clear();
            localStorage.setItem('APP_VERSION', 1);
            this.$router.push('/login');
        }
        if (this.profile !==null) {
            this.$store.dispatch("resetData");
        }
    },
}
</script>
<style lang="stylus">
.bx-shadow
    box-shadow 0 4px 1px 0 rgba(0,0,0,.01)
    background-color #229a87
.brand-head
    color white
    padding 15px 0px
    h4
        margin-bottom 0px
.navbar-nav
    li
        padding 9px 10px 9px 25px
        &.active
            background-color white
            a
                color #1A263A
                .fa
                    color #1A263A
        a
            color white
            font-weight 500
            // font-weight bold
            .fa
                color white 
// .text-warning
//     font-weight bold
    // font-size 1.2rem
</style>

